import moment from 'moment';
import { Subject } from 'rxjs';
import {
    FIELD_TYPE_BOOLEAN,
    FIELD_TYPE_DATE,
    FIELD_TYPE_NUMBER,
    FIELD_TYPE_TEXT,
    UPDATE_ERROR_REPORT_OPTIN_FUNCION,
    FIELD_TYPE_SERVICE,
    FIELD_TYPE_WAGE_KEYS
} from '../../../../app.constants';
import { EmployeeStatusEnum } from '../../../../shared/enums/employee-status.enum';
import { GenderEnum } from '../../../../shared/enums/gender.enum';
import { DateConverter } from '../../../../utils/date-converter';
import { BaseService } from '../../../../core/abstractions/base-service';
import { AppInjector } from '../../../../core/util/app.injector';
import { FunctionOptions } from '../../../../core/domain/function-options';
import { InfoDialogComponent } from '../../../../core/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FirestoreService } from 'src/app/core/services/firestore.service';
import { FileUpload } from 'src/app/shared/models/file-upload.model';

export abstract class AbstractImportationStrategy {

    public progress = 0;
    public onProgress: Subject<number>;
    protected service: BaseService<any> = AppInjector.get(BaseService);
    protected dialog: MatDialog = AppInjector.get(MatDialog);

    abstract import(...params): Promise<any>;

    /**
     * Check if has some opt-in by his name and birthday
     * @param importedUser The full imported personal data extracted from the spreadsheet
     * @param optins The optins stored in the database
     */
    protected findOptinUser(importedUser: any, optins: any[]) {
        return optins.find(optin => {
            let matchCount = 0;
            if (importedUser.firstName?.toLowerCase() === optin.firstName?.toLowerCase()){
                matchCount ++;
            }
            if (importedUser.lastName?.toLowerCase() === optin.lastName?.toLowerCase()){
                matchCount ++;
            }
            if (DateConverter.toString(importedUser.birthday) === optin.birthday){
                matchCount ++;
            }
            if ([
                    EmployeeStatusEnum.DATA_EXPORT_PENDING,
                    EmployeeStatusEnum.DATA_MISSING,
                    EmployeeStatusEnum.DATA_EXPORT_INCOMPLETE
                ].includes(optin.status)) {
                if (matchCount === 3) {
                    importedUser.id = optin.id;
                    return true;
                } else if (matchCount === 2) {
                    this.updateErrorReport(optin.id).then();
                    return false;
                } else if (matchCount < 2) {
                    return false;
                }
            }
        });
    }

    protected async updateErrorReport(id: string) {
        await this
            .service
            .callFunction({id, errorReport: 'messages.optin-with-some-matched-data'},
                UPDATE_ERROR_REPORT_OPTIN_FUNCION,
                new FunctionOptions('callable')).toPromise();
    }

    /**
     * Check if has some opt-in by his registration number
     * @param importedFinancialData The full imported financial extracted from the spreadsheet
     * @param importedUsers List of imported users from file
     */
    protected matchOptinFinancialData(importedFinancialData: any, importedUsers: any[]) {
        return !!importedUsers.find(
            importedUser =>
                importedFinancialData.registration?.toString() === importedUser.registration?.toString()
        );
    }

    protected getFieldValue(data: any, fieldSynonyms: string[] = []) {
        for (const synonym of fieldSynonyms) {
            if (data[synonym] !== undefined && data[synonym].toString().length) {
                return { value: data[synonym], synonym };
            }
        }
        return {};
    }

    /**
     * Build a object according to the spreadsheet extracted data and fields (columns) registered in the firestore.
     * @param importedData A imported data from the spreadsheet that will be built according to the fields passed into
     * params
     * @param fields The list of the fields to help to build the object
     */
    protected buildObject(importedData, fields): any {
        const result = {};
        if (fields) {
            Object.keys(fields).forEach((key) => {
                const { value, synonym } = this.getFieldValue(importedData, fields[key].synonyms);
                if (value !== undefined) {
                    if (fields[key].type === FIELD_TYPE_TEXT) {
                        result[key] = typeof value === 'string' ? value.trim() : value;
                    } else if (fields[key].type === FIELD_TYPE_BOOLEAN) {
                        result[key] = ['ja', 'yes', 'j'].includes(value.toString().trim().toLowerCase());
                    } else if (fields[key].type === FIELD_TYPE_NUMBER) {
                        let numberValue = value;
                        if (typeof numberValue === 'string' && (numberValue.includes(',') || numberValue.includes('€')) ) {
                            numberValue = numberValue.replace('.', '').replace(',', '.').replace('€', '');
                        }
                        if (isNaN(numberValue)) {
                            result[key] = value.trim();
                        } else {
                            result[key] = Number(numberValue);
                        }
                    } else if (fields[key].type === FIELD_TYPE_DATE) {
                        if (typeof value === 'string') {
                            result[key] = value.trim();
                        } else {
                            result[key] = moment(value).format('DD.MM.YYYY');
                        }
                    } else if (fields[key].type === FIELD_TYPE_SERVICE) {
                        const registration = this.getFieldValue(importedData, fields.registration.synonyms);
                        const billingMonth = this.getFieldValue(importedData, fields.billingMonth.synonyms);
                        const amount = Number(value.replace('.', '').replace(',', '.').replace('€', ''));
                        result[key] = {
                            registration: Number(registration.value),
                            wageTypeNumber: synonym,
                            amount,
                            billingMonth: billingMonth.value,
                        };
                    } else if (fields[key].type === FIELD_TYPE_WAGE_KEYS) {
                        const registration = this.getFieldValue(importedData, fields.registration.synonyms);
                        const billingMonth = this.getFieldValue(importedData, fields.billingMonth.synonyms);
                        const amount = Number(value.replace('.', '').replace(',', '.').replace('€', ''));
                        result[key] = {
                            registration: Number(registration.value),
                            serviceKey: synonym,
                            amount,
                            billingMonth: billingMonth.value,
                        };
                    }
                }
            });
        }
        return result;
    }

    protected getGender(genderImported: string) {
        switch (genderImported.toLowerCase()) {
            case 'männlich':
                return GenderEnum.MALE;
            case 'weiblich':
                return GenderEnum.FEMALE;
            case 'm':
                return GenderEnum.MALE;
            case 'w':
                return GenderEnum.FEMALE;
            default:
                return GenderEnum.OTHER;
        }
    }

    protected emitProgress(percentage: number) {
        this.progress += Number(percentage.toFixed(1));
        this.onProgress.next(this.progress);
        if (Number(this.progress.toFixed()) >= 100) {
            this.onProgress.next(100);
            this.onProgress.complete();
        }
    }

    protected openDialogWageHistoryDataNotFound() {
        this.dialog.open(InfoDialogComponent, {
            panelClass: 'curved-modal',
            data: {
                text: 'messages.no-history-wage-data-found',
                isConfirmationDialog: false,
                doneBtnTitle: 'system.got-it'
            }
        });
    }

    /*
     * This file upload is temporary, it will help in the initial phase of imported employees
     */
    saveFilesInFirestoreForFutureDebug(
        firestoreService: FirestoreService,
        file: File,
        companyId: string
    ) {
        const fileUpload = new FileUpload();
        fileUpload.file = file;
        return firestoreService.uploadFile(
            fileUpload,
            `finstein/debug/companies/${companyId}`
        ).toPromise();
    }
}
