import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectionType } from '@swimlane/ngx-datatable';
import { BaseComponent } from '../../abstractions/base.component';
import { Observable } from 'rxjs';
import * as lodash from 'lodash';

@Component({
    selector: 'app-view-import-data',
    templateUrl: './view-import-data.component.html',
    styleUrls: ['./view-import-data.component.scss'],
})
export class ViewImportDataComponent
    extends BaseComponent
    implements OnInit, AfterViewInit
{
    items: any[] = [];
    itemsSelected: any[] = [];
    loadingIndicator = true;
    
    importDataTitleText = 'optins.import-data.details';
    importDataDescriptionText = 'optins.import-data.description';
    confirmActionText = 'system.upload';

    columns: {
        prop: string,
        name: string,
        frozenLeft?: boolean,
        customValue?: (arg: any) => string
    }[] = [
        {
            prop: 'lastName',
            name: 'users.lastName',
            frozenLeft: true
        },
        {
            prop: 'firstName',
            name: 'users.firstName',
            frozenLeft: true
        },
        {
            prop: 'registration',
            name: 'settings.optins-importation.registration'
        },
        {
            prop: 'birthday',
            name: 'common.birthday'
        },
        {
            prop: 'addressStreet',
            name: 'common.street'
        },
        {
            prop: 'addressNumber',
            name: 'common.address-number'
        },
        {
            prop: 'postalCode',
            name: 'common.zip-code'
        },
        {
            prop: 'city',
            name: 'common.city'
        },
        {
            prop: 'country',
            name: 'common.country'
        },
        {
            prop: 'addressState',
            name: 'common.state'
        },
        {
            prop: 'entryDate',
            name: 'settings.optins-importation.entry-date'
        },
        {
            prop: 'leavingDate',
            name: 'settings.optins-importation.leaving-date'
        },
        {
            prop: 'payAs',
            name: 'settings.optins-importation.pay-as'
        },
        {
            prop: 'taxClass',
            name: 'settings.optins-importation.tax-class'
        },
        {
            prop: 'taxFactor',
            name: 'settings.optins-importation.tax-factor'
        },
        {
            prop: 'children',
            name: 'settings.optins-importation.children'
        },
        {
            prop: 'religion',
            name: 'settings.optins-importation.religion'
        },
        {
            prop: 'healthInsuranceBasicContribution',
            name: 'settings.optins-importation.health-insurance-basic-contribution'
        },
        {
            prop: 'healthInsuranceContribution',
            name: 'settings.optins-importation.health-insurance-contribution'
        },
        {
            prop: 'careContribution',
            name: 'settings.optins-importation.care-contribution'
        },
        {
            prop: 'annualTaxAllowance',
            name: 'settings.optins-importation.annual-tax-allowance'
        }
    ]
    checkboxable = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<ViewImportDataComponent>
    ) {
        super();

        this.importDataTitleText = data?.titleText ?? this.importDataTitleText;
        this.importDataDescriptionText = data?.descriptionText ?? this.importDataDescriptionText;
        this.confirmActionText = data?.confirmActionText ?? this.confirmActionText;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.columns = this.data?.columns ?? this.columns;
        this.checkboxable = this.data?.checkboxable ?? this.checkboxable
        if (this.data.importingDataFunction) {
            this.handleImportingData(this.data.importingDataFunction);
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        // Expanding table animation
        if (!this.data.importingDataFunction) {
            setTimeout(() => {
                this.items = this.data?.items;
                this.itemsSelected =  [...this.items];
                this.loadingIndicator = false;
            }, 300);
        }
    }

    onSelectEmployee({selected}) {
        this.itemsSelected = [];
        this.itemsSelected.push(...selected);
    }

    confirmAction() {
        if (this.data.cutomConfirmAction) {
            this.data.cutomConfirmAction();
        } else {
            this.dialog.close(this.itemsSelected);
        }
    }

    getCellValue(column, row) {
        const value = lodash.get(row, column.prop);
        return column?.customValue ? column.customValue(value) : value;
    }

    handleImportingData(importingDataFunction: Observable<any>) {
        importingDataFunction.subscribe({
            next: (res) => {
                this.items = res;
                this.itemsSelected = res;
                this.loadingIndicator = false;
            },
            error: () => {
                this.loadingIndicator = false;
            }
        });
    }

    protected readonly SelectionType = SelectionType;
}
