<app-page-title [title]="title" [showContent]="true" [showBackButton]="true">
    <button
        *ngIf="!isViewMode"
        rightContent
        type="submit"
        form="form"
        class="btn btn-outline-primary rounded page-title-button"
        translate>
        system.save
    </button>
</app-page-title>

<form [formGroup]="editForm" id="form" (ngSubmit)="onSubmit()" class="row space-content p-2" *ngIf="formLoaded">
    <app-expandable-card-ribbon title="optins.import-data.personal-data"
                                color="color-pale-violet-red"
                                class="col-12"
                                [editForm]="true"
                                [currentForm]=" editForm.get('firstName')"
                                (toggleEditBtn)="toggleForm()"
                                (save)="submitPersonalCompanyData()"
                                [showEditButton]="showEditPersonData"
                                [showNotificationIcon]="isPersonalDataFormInvalid()"
                                [showHistoricalChange]="hasHistoricalDataForPersonalData()"
                                [openOnInit]="true">
        <div class="row pl-6 pr-6">
            <div class="col-sm-4">
                <app-input
                    label="users.lastName"
                    [form]="editForm"
                    controlName="lastName"
                    [item]="item"
                ></app-input>
            </div>

            <div class="col-sm-4">
                <app-input
                    label="users.firstName"
                    [form]="editForm"
                    [item]="item"
                    controlName="firstName"
                >
                </app-input>
            </div>

            <div class="col-sm-4">
                <app-input
                    label="common.email"
                    [form]="editForm"
                    controlName="email"
                    [item]="item"
                >
                </app-input>
            </div>

            <div class="col-sm-4">
                <app-input
                    type="date"
                    [item]="item"
                    label="common.birthday"
                    controlName="birthday"
                    [form]="editForm"
                >
                </app-input>
            </div>
        </div>
    </app-expandable-card-ribbon>
    <div *ngIf="!showOnlyPersonData" class="col-12">
        <app-expandable-card-ribbon title="employees.address"
                                    color="color-sandy-brown"
                                    class="col-12"
                                    [showHistoricalChange]="hasHistoricalDataForAddress()">
            <div class="row pl-6 pr-6">
                <div class="col-4">
                    <app-input label="common.street"
                               controlName="addressStreet"
                               [form]="editForm.get('importedData')"
                               [item]="item"
                               customApprovalProperty="importedData.addressStreet">
                    </app-input>
                </div>

                <div class="col-2">
                    <app-input label="common.address-number"
                               controlName="addressNumber"
                               [form]="editForm.get('importedData')"
                               [item]="item"
                               customApprovalProperty="importedData.addressNumber">
                    </app-input>
                </div>

                <div class="col-4">
                    <app-input label="common.additional"
                               type="text"
                               controlName="addressAdditional"
                               [form]="editForm.get('importedData')"
                               [item]="item"
                               customApprovalProperty="importedData.addressAdditional">
                    </app-input>
                </div>

                <div class="col-2">
                    <app-input label="common.zip-code"
                               type="number"
                               controlName="postalCode"
                               [form]="editForm.get('importedData')"
                               [item]="item"
                               customApprovalProperty="importedData.postalCode">
                    </app-input>
                </div>

                <div class="col-4">
                    <app-input label="common.city"
                               type="text"
                               controlName="city"
                               [form]="editForm.get('importedData')"
                               [item]="item"
                               customApprovalProperty="importedData.city">
                    </app-input>
                </div>

                <div class="col-4">
                    <app-select label="common.state"
                                controlName="addressState"
                                [form]="editForm.get('importedData')"
                                [items]="states"
                                [multiple]="false"
                                [item]="item"
                                customApprovalProperty="importedData.addressState">
                    </app-select>
                </div>

                <div class="col-4">
                    <app-input label="common.country"
                               controlName="country"
                               [form]="editForm.get('importedData')"
                               [item]="item"
                               customApprovalProperty="importedData.country">
                    </app-input>
                </div>
            </div>
        </app-expandable-card-ribbon>

        <app-expandable-card-ribbon title="optins.import-data.payroll-data"
                                    color="color-m-slate-blue"
                                    class="col-12"
                                    [showNotificationIcon]="isPayrollDataFormInvalid()"
                                    [showHistoricalChange]="hasHistoricalDataForPayroll()">
            <fieldset class="row col-12">
                <legend translate>optins.import-data.payroll-data</legend>
                <div class="row pl-6 pr-6">
                    <div class="col-sm-4">
                        <app-input
                            type="number"
                            label="settings.optins-importation.health-insurance-number"
                            controlName="healthInsuranceNumber"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.healthInsuranceNumber">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="number"
                            label="settings.optins-importation.health-insurance-number-percent"
                            controlName="healthInsuranceNumberPercent"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.healthInsuranceNumberPercent">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-select
                            controlName="additionalHealthInsuranceContribution"
                            label="settings.optins-importation.additional-health-insurance-contribution"
                            [form]="getImportedDataFormGroup()"
                            [clearable]="false"
                            [booleanOptions]="true"
                            [item]="item"
                            customApprovalProperty="importedData.additionalHealthInsuranceContribution">
                        </app-select>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="number"
                            label="settings.optins-importation.additional-health-insurance-contribution-rate"
                            controlName="additionalHealthInsuranceContributionRate"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.additionalHealthInsuranceContributionRate">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-select
                            controlName="careInsuranceContribution"
                            label="settings.optins-importation.care-insurance-contribution"
                            [form]="getImportedDataFormGroup()"
                            [clearable]="false"
                            [booleanOptions]="true"
                            [item]="item"
                            customApprovalProperty="importedData.careInsuranceContribution">
                        </app-select>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="currency"
                            label="settings.optins-importation.health-insurance-contribution"
                            controlName="healthInsuranceContribution"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.healthInsuranceContribution">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="currency"
                            label="settings.optins-importation.care-contribution"
                            controlName="careContribution"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.careContribution">
                        </app-input>
                    </div>
                </div>
            </fieldset>

            <fieldset class="row col-12">
                <legend translate>optins.fieldsets.employee-information</legend>
                <div class="row pl-6 pr-6">
                    <div class="col-sm-4" *ngIf="!showOnlyPersonData">
                        <app-select controlName="gender"
                                    label="optins.gender"
                                    [form]="getImportedDataFormGroup()"
                                    [items]="genders"
                                    bindLabel="value"
                                    [multiple]="false"
                                    [item]="item"
                                    [disabled]="isViewMode"
                                    showProperty="label"
                                    [searchable]="false"
                                    customApprovalProperty="importedData.gender">
                        </app-select>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            label="settings.optins-importation.registration"
                            controlName="registration"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.registration">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="date"
                            label="settings.optins-importation.entry-date"
                            controlName="entryDate"
                            [form]="editForm.get('importedData')"
                            [showRequiredMarker]="true"
                            [item]="item"
                            customApprovalProperty="importedData.entryDate">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="date"
                            label="settings.optins-importation.leaving-date"
                            controlName="leavingDate"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.leavingDate">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            label="settings.optins-importation.pay-as"
                            controlName="payAs"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.payAs">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="currency"
                            label="settings.optins-importation.health-insurance-basic-contribution"
                            controlName="healthInsuranceBasicContribution"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.healthInsuranceBasicContribution">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="number"
                            label="settings.optins-importation.tax-class"
                            controlName="taxClass"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.taxClass">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="number"
                            label="settings.optins-importation.tax-factor"
                            controlName="taxFactor"
                            numericType="float"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.taxFactor">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="number"
                            numericType="float"
                            label="settings.optins-importation.children"
                            controlName="children"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.children">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-select
                            controlName="religion"
                            label="settings.optins-importation.religion"
                            [form]="editForm.get('importedData')"
                            [clearable]="false"
                            [booleanOptions]="true"
                            [item]="item"
                            customApprovalProperty="importedData.religion">
                        </app-select>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            type="currency"
                            label="settings.optins-importation.annual-tax-allowance"
                            controlName="annualTaxAllowance"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.annualTaxAllowance">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            label="settings.optins-importation.contribution-group-keys"
                            controlName="contributionGroupKey"
                            mask="0-0-0-0"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.contributionGroupKey">
                        </app-input>
                    </div>

                    <div class="col-sm-4">
                        <app-input
                            label="settings.optins-importation.personal-group-keys"
                            controlName="personalGroupKey"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.personalGroupKey">
                        </app-input>
                    </div>
                </div>
            </fieldset>

            <fieldset class="row col-12">
                <legend translate>optins.fieldsets.working-hours</legend>
                <div class="row pl-6 pr-6">
                    <div class="col-4">
                        <app-input
                            label="settings.optins-importation.regular-daily-working-hours"
                            controlName="regularDailyWorkingHours"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.regularDailyWorkingHours">
                        </app-input>
                    </div>

                    <div class="col-4">
                        <app-input
                            label="settings.optins-importation.working-days-per-week"
                            controlName="workingDaysPerWeek"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.workingDaysPerWeek">
                        </app-input>
                    </div>

                    <div class="col-4">
                        <app-input
                            label="settings.optins-importation.regular-weekly-working-hours"
                            controlName="weeklyWorkingHours"
                            [form]="editForm.get('importedData')"
                            [item]="item"
                            customApprovalProperty="importedData.weeklyWorkingHours">
                        </app-input>
                    </div>
                </div>
            </fieldset>
        </app-expandable-card-ribbon>

        <app-expandable-card-ribbon title="optins.import-data.wage-data-summary"
                                    color="color-light-blue-5"
                                    *ngIf="isEditMode || (wageDataSummaryFormArray && wageDataSummaryFormArray.controls.length > 0)"
                                    class="col-12"
                                    formGroupName="importedData"
                                    [showNotificationIcon]="isWageDataFormInvalid()"
                                    [showHistoricalChange]="hasHistoricalDataForWageData()">
            <ng-container *ngIf="wageDataSummaryFormArray.controls?.length; else empty">
                <div class="row pl-6 pr-6 d-flex justify-content-end" formArrayName="wageData">
                    <div class="col-12">
                        <div class="row align-items-center"
                             *ngFor="let control of wageDataSummaryFormArray.controls; let i = index" [formGroupName]="i">
                            <div class="col-2 d-flex align-items-center">
                                <ng-container *ngIf="getServiceName(control) as name; else noServiceName">
                                    <span class="service-name-title-color">{{name}}</span>
                                </ng-container>
                                <ng-template #noServiceName>
                                    <span class="service-name-grey-color"
                                          translate>action-sequence.not-applicable</span>
                                </ng-template>
                                <div *ngIf="!item?.importedData?.wageDataSummary || item?.importedData?.wageDataSummary?.length === 0"
                                     class="col service-name text-primary">
                                    <div class="spinner-grow text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>

                            <app-input
                                [type]="serviceKeyType"
                                label="settings.optins-importation.service-key"
                                controlName="serviceKey"
                                [form]="control" class="col"
                                [item]="item"
                                [customApprovalProperty]="getWageDataCustomProperty('serviceKey', i)"></app-input>

                            <app-input
                                type="currency"
                                label="common.amount"
                                controlName="amount"
                                [form]="control" class="col"
                                [item]="item"
                                [customApprovalProperty]="getWageDataCustomProperty('amount', i)"></app-input>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #empty>
                <div class="jumbotron jumbotron-fluid pl-4">
                    <div class="container d-flex align-items-center flex-column">
                        <div class="d-flex align-items-center justify-content-between text-nowrap">
                            <h1 translate>common.no-search-elements</h1>
                            <i class="material-icons search-off ml-2">search_off</i>
                        </div>
                    </div>
                </div>
            </ng-template>

        </app-expandable-card-ribbon>

        <app-expandable-card-ribbon title="optins.import-data.wage-data"
                                    color="color-light-blue-5"
                                    *ngIf="isEditMode || (wageDataFormArray && wageDataFormArray.controls.length > 0)"
                                    class="col-12"
                                    formGroupName="importedData"
                                    [showNotificationIcon]="isWageDataFormInvalid()"
                                    [showHistoricalChange]="hasHistoricalDataForWageData()">
            <ng-container *ngIf="wageDataFormArray.controls?.length; else empty">
                <div class="row pl-6 pr-6 d-flex justify-content-end" formArrayName="wageData">
                    <button *ngIf="isEditMode && !isViewMode" leftContent type="button"
                            [disabled]="isViewMode"
                            class="btn btn-outline-primary rounded d-flex align-items-center text-nowrap mb-5"
                            (click)="addWageData()" translate>
                        optins.add-new-wage-data
                        <i class="material-icons icon-add-field">add</i>
                    </button>
                    <div class="col-12">
                        <div class="row align-items-center"
                             *ngFor="let control of wageDataFormArray.controls; let i = index" [formGroupName]="i">
                            <div class="col-2 d-flex align-items-center">
                                <ng-container *ngIf="getServiceName(control) as name; else noServiceName">
                                    <span class="service-name-title-color">{{name}}</span>
                                </ng-container>
                                <ng-template #noServiceName>
                                    <span class="service-name-grey-color"
                                          translate>action-sequence.not-applicable</span>
                                </ng-template>
                                <div *ngIf="!wageDataList || wageDataList.length === 0"
                                     class="col service-name text-primary">
                                    <div class="spinner-grow text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>

                            <app-input
                                [type]="serviceKeyType"
                                label="settings.optins-importation.service-key"
                                controlName="serviceKey"
                                [form]="control" class="col"
                                [item]="item"
                                [customApprovalProperty]="getWageDataCustomProperty('serviceKey', i)"></app-input>

                            <app-input
                                type="string"
                                label="settings.optins-importation.billing-month"
                                controlName="billingMonth"
                                [form]="control" class="col"
                                [item]="item"
                                [customApprovalProperty]="getWageDataCustomProperty('billingMonth', i)"></app-input>

                            <app-input
                                *ngIf="control.get('amountMultiplier')?.value"
                                type="number"
                                label="settings.optins-importation.amount-multiplier"
                                controlName="amountMultiplier"
                                [form]="control" class="col"
                                [item]="item"
                                [customApprovalProperty]="getWageDataCustomProperty('amountMultiplier', i)"></app-input>

                            <app-input
                                type="currency"
                                label="common.amount"
                                controlName="amount"
                                [form]="control" class="col"
                                [item]="item"
                                [customApprovalProperty]="getWageDataCustomProperty('amount', i)"></app-input>

                            <button type="button" class="icon btn-muted mb-4"
                                    [disabled]="isViewMode"
                                    *ngIf="!isViewMode && (isEditMode && this.wageDataFormArray.length > 1)"
                                    (click)="removeWageData(i, control)">
                                <i class="material-icons">delete</i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #empty>
                <div class="jumbotron jumbotron-fluid pl-4">
                    <div class="container d-flex align-items-center flex-column">
                        <div class="d-flex align-items-center justify-content-between text-nowrap">
                            <h1 translate>common.no-search-elements</h1>
                            <i class="material-icons search-off ml-2">search_off</i>
                        </div>
                        <ng-container *ngIf="isEditMode && !isViewMode">
                            <span translate>optins.add-new-wage-data</span>
                            <button class="btn-action mt-1" (click)="addWageData()" [disabled]="isViewMode">
                                <i class="material-icons">add</i>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </ng-template>

        </app-expandable-card-ribbon>
    </div>
</form>
