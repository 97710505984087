<div class="d-flex justify-content-between" data-test="header">
    <h1 class="pl-2 page-title my-auto" translate data-test="import-data-title">
        {{ importDataTitleText }}
    </h1>

    <div class="justify-content-end mt-4" mat-dialog-actions data-test="action-buttons">
        <button
            tabindex="-1"
            type="button"
            class="btn btn-outline-light btn-lg mr-3"
            [mat-dialog-close]="false"
            translate
            data-test="cancel-button"
        >
            optins.import-data.cancel
        </button>
        <button
            tabindex="-1"
            type="button"
            class="btn btn-primary btn-lg"
            (click)="confirmAction()"
            *ngIf="items.length > 0"
            [disabled]="itemsSelected.length === 0"
            translate
            data-test="confirm-button"
        >
            {{ confirmActionText }}
        </button>
    </div>
</div>

<span class="pl-2 page-description" translate data-test="import-data-description">
    {{ importDataDescriptionText }}
</span>

<div class="text-center pl-6 pr-6 mt-6" mat-dialog-content data-test="datatable-container">
    <ng-container *ngIf="!loadingIndicator && items.length === 0; else elseTemplate">
        <div class="no-optins-found" data-test="no-optins-found">
            <h5 translate>messages.no-optins-found-in-xlsx</h5>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <ngx-datatable
            #optinList
            class="material expandable"
            columnMode="force"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="50"
            [rows]="items"
            [selected]="itemsSelected"
            [selectionType]="SelectionType.checkbox"
            [selectAllRowsOnPage]="true"
            (select)="onSelectEmployee($event)"
            [scrollbarV]="true"
            [scrollbarH]="true"
            [loadingIndicator]="loadingIndicator"
            [virtualization]="false"
            [externalPaging]="false"
            data-test="datatable"
        >
            <ngx-datatable-column
                [width]="15"
                [frozenLeft]="true"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="checkboxable"
                [checkboxable]="checkboxable"
                cellClass="datatable-body-cell-checkbox"
                data-test="datatable-column-checkbox"
            >
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let col of columns"
                [name]="col.name | translate"
                [prop]="col.prop"
                [frozenLeft]="col.frozenLeft"
                data-test="datatable-column">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <span data-test="datatable-cell">{{ getCellValue(col, row) }}</span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </ng-template>
</div>
