import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import lodash from 'lodash';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ViewImportDataComponent } from './view-import-data.component';
import { BaseService } from '../../abstractions/base-service';
import { IMPORT_EMPLOYEE_DATA_FUNCTION } from 'src/app/app.constants';

@Injectable({
    providedIn: 'root',
})
export class ViewImportDataService {
    private requestInProgress = new BehaviorSubject<boolean>(false);

    constructor(private dialog: MatDialog, private service: BaseService<any>) {}

    public open(importedUsers: any[], columns = [], descriptionText = '') {
        return this.dialog
            .open(ViewImportDataComponent, {
                maxWidth: '95vw',
                minWidth: '95vw',
                maxHeight: '95vh',
                data: {
                    items: importedUsers,
                    columns: columns.length ? columns : null,
                    descriptionText,
                },
                panelClass: 'view-import-data-modal',
            })
            .afterClosed();
    }

    public importOptinData(
        employees: Array<any>,
        batchSize = 20
    ): Observable<any> {
        if (employees?.length) {
            const batchRequest = lodash
                .chunk(employees, batchSize)
                .map((chunck) =>
                    this.service.callFunction(chunck, IMPORT_EMPLOYEE_DATA_FUNCTION, {
                        type: 'callable',
                        httpMethod: 'POST',
                    })
                );
            return zip(...batchRequest).pipe(
                map(
                    (
                        responses: Array<
                            Array<{
                                details: {
                                    fulfilled: number;
                                    rejected: number;
                                };
                            }>
                        >
                    ) => {
                        return responses.flat().reduce(
                            (next, acc) => {
                                next.fulfilled += acc.details.fulfilled;
                                next.rejected += acc.details.rejected;
                                return next;
                            },
                            { fulfilled: 0, rejected: 0 }
                        );
                    }
                )
            );
        } else {
            return of(false);
        }
    }

    public createRequestOptin(
        employees: Array<any>,
        functionName: string,
    ): Observable<any> {
        if (employees?.length) {
            return this.service.callFunction(employees, functionName, {
                type: 'callable',
                httpMethod: 'POST',
            });
        } else {
            return of(false);
        }
    }

    isRequestInProgress(): Observable<boolean> {
        return this.requestInProgress.asObservable();
    }
}
