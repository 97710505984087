import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { ViewImportDataComponent } from './view-import-data.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ViewImportDataComponent],
    imports: [
        CommonModule,
        MatRippleModule,
        MatDialogModule,
        NgxDatatableModule,
        TranslateModule
    ],
    exports: [ViewImportDataComponent],
})
export class ViewImportDataModule {
}
