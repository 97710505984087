import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import lodash from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, take } from 'rxjs/operators';
import {
    AccountingSystemsEnum,
    isNumberTypeKeySystem,
} from 'src/app/shared/enums/accounting-systems.enum';
import {
    CONSULTANCY_PROCESS_COLLECTION,
    EMPLOYEES_COLLECTION,
    OPTIN_PERCENTAGE_STATUS,
    OP_EQUALS,
    REMOVE_OPTIN_FUNCTION,
    SEND_OPTIN_TO_DATA_EXPORT_FUNCTION,
} from '../../../app.constants';
import { BaseService } from '../../../core/abstractions/base-service';
import { FireLoggingService } from '../../../core/services/fire-logging.service';
import { ClauseModel, FilterModel } from '../../../shared/models/filter.model';
import { BookkeepingKeyService } from '../bookkeeping-suggestions/bookkeeping-keys/services/bookkeeping-key.service';

@Injectable({
    providedIn: 'root',
})
export class OptinsService extends BaseService<any> {
    constructor(
        private bookkeepingKeyService: BookkeepingKeyService,
        protected firestore: AngularFirestore,
        protected http: HttpClient,
        protected functions: Functions,
        protected fireLogging: FireLoggingService
    ) {
        super(firestore, http, functions, fireLogging);
    }

    removeOptin(optinId: string) {
        return httpsCallableData<any, any>(this.functions, REMOVE_OPTIN_FUNCTION)(optinId);
    }

    sendOptinToDataExport(optins: Array<string>) {
        return httpsCallableData<any, any>(this.functions, SEND_OPTIN_TO_DATA_EXPORT_FUNCTION)(
            optins
        );
    }

    getEmployeesWithProgress(companyId: string, clauses: ClauseModel[]) {
        const filter = new FilterModel();
        filter.clauses = clauses;
        return this.search(EMPLOYEES_COLLECTION, filter).pipe(
            map((employees) =>
                this.appendEmployeeProgressToOptin(employees, companyId)
            )
        );
    }

    private appendEmployeeProgressToOptin(
        optins: any,
        companyId: string
    ): Array<
        Observable<{
            lastName: string;
            firstName: string;
            email: string;
            status: string;
            progress: number;
        }>
    > {
        return optins.map((optin) => {
            return this.getProgressEmployeeByEmail(optin.email, companyId).pipe(
                map((res) => {
                    optin.progress = res.weight;
                    optin.progressTooltipMessage = res.tooltipMessage;
                    return optin;
                })
            );
        });
    }

    private getProgressEmployeeByEmail(
        email: string,
        companyId: string
    ): Observable<any> {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'email',
                opStr: OP_EQUALS,
                value: email,
            },
            {
                fieldPath: 'companyId',
                opStr: OP_EQUALS,
                value: companyId,
            },
        ];
        return this.firestore
            .collection(EMPLOYEES_COLLECTION, (ref) => {
                return this.buildFirebaseFilter(ref, filter);
            })
            .get()
            .pipe(
                take(1), // Search only once
                concatMap((employees) => {
                    const employeeId = employees.docs.at(0).id;
                    return this.firestore
                        .collection(EMPLOYEES_COLLECTION)
                        .doc(employeeId)
                        .collection(CONSULTANCY_PROCESS_COLLECTION, (ref) =>
                            ref.orderBy('createdAt', 'desc')
                        )
                        .snapshotChanges() // Listen all changes on CONSULTANCY_PROCESS_COLLECTION
                        .pipe(
                            map((consultancyProcessList) => {
                                const cop = consultancyProcessList
                                    .at(0)
                                    .payload.doc.data();
                                return this.calculateProgress(cop); // Returns the calculated progress from the cop fields
                            }),
                            catchError((error) => {
                                const message = error.error
                                    ? error.error.message
                                    : error.message;
                                const path = `${EMPLOYEES_COLLECTION}/${employeeId}/${CONSULTANCY_PROCESS_COLLECTION}`;
                                this.fireLogging.sendErrorLog(
                                    `[getProgressEmployeeByEmail(optins.service.ts)] An error occurred while fetching item into collection ${path}, details: ${
                                        message || JSON.stringify(error)
                                    }`
                                );
                                return of({ error });
                            })
                        );
                }),
                catchError((error) => {
                    const message = error.error
                        ? error.error.message
                        : error.message;
                    this.fireLogging.sendErrorLog(
                        `[getProgressEmployeeByEmail(optins.service.ts)] An error occurred while fetching item into collection ${EMPLOYEES_COLLECTION}, details: ${
                            message || JSON.stringify(error)
                        }`
                    );
                    return of({ error });
                })
            );
    }

    fetchAllWageKeys(importType: AccountingSystemsEnum, companyId: string) {
        return this.bookkeepingKeyService.fetchAllKeys(importType, companyId).pipe(
            map(({ services, keys }) => {
                const result = lodash.concat(services, keys).map((key) => {
                    if (key.tax) {
                        key.tax.code = key.tax.code ?? [];
                        if (isNumberTypeKeySystem(importType)) {
                            key.tax.code = key.tax.code.map(Number);
                        }
                    }

                    key.code = key.code ?? [];
                    if (isNumberTypeKeySystem(importType)) {
                        key.code = key.code.map(Number);
                    }
                    return key;
                });
                return result;
            })
        );
    }

    calculateProgress(consultancyProcess) {
        return Object.keys(OPTIN_PERCENTAGE_STATUS).reduce(
            (progress, field) => {
                if (consultancyProcess[field]) {
                    progress.weight += OPTIN_PERCENTAGE_STATUS[field].weight;
                    progress.tooltipMessage =
                        OPTIN_PERCENTAGE_STATUS[field].tooltipMessage;
                }
                return progress;
            },
            { weight: 0, tooltipMessage: '' }
        );
    }
}
