/* src/app/modules/company/optins/form/optin-form.component.scss */
.icon {
  display: inline-flex;
  margin-right: 0px !important;
  margin-top: 5px;
  color: #5b6c80;
}
.service-name-title-color {
  color: #275fa1;
  font-weight: bold;
}
.service-name-grey-color {
  color: #6c757d;
  font-weight: bold;
}
.jumbotron {
  background-color: transparent !important;
}
.jumbotron h1 {
  font-size: 1.5rem;
}
fieldset {
  padding: 10px;
  margin: 15px;
  border: 1px solid #f1f0f0;
  width: 100%;
  border-radius: 9px;
}
fieldset legend {
  width: auto;
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
}
/*# sourceMappingURL=optin-form.component-GOUYQDFQ.css.map */
