/* src/app/core/components/view-import-data/view-import-data.component.scss */
.mdc-dialog__container {
  padding-left: 24px;
}
.mat-mdc-dialog-content {
  max-height: 62vh;
}
.no-optins-found {
  margin-top: 9%;
  margin-bottom: 9%;
}
.page-title {
  font-size: 1.3rem;
}
.cursor-default {
  cursor: default;
}
.mat-mdc-dialog-content {
  max-height: none;
}
/*# sourceMappingURL=view-import-data.component-FODBWXQ4.css.map */
